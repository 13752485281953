import LandingPageComponent from '../../../../component/landing';
import React from 'react';

const IndexPage = () => {
  return (
    <LandingPageComponent
      isFinal={true}
      defaultUtmMedium='form'
      defaultUtmSource='tiktok'
    />
  );
};

export default IndexPage;
